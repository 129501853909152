@import "~bootstrap/dist/css/bootstrap.css";

.fudrr-site-navbar {
  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 20px 20px;
  padding: 1.25rem 0.5rem;

  .btn {
    border-radius: 2.5rem;
  }

  .btn-primary {
    background-color: #5564ae;
    border-color: #5564ae;

    &:hover {
      background-color: darken(#5564ae, 10%);
      color: #fff;
    }
  }

  .btn-outline-primary {
    color: #5564ae;
    border-color: #5564ae;

    &:hover {
      background-color: lighten(#0f8835, 0%);
      color: #fff;
    }
  }
}

.fudrr-signup-callout-navbar {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  .btn {
    border-radius: 2.5rem;
  }

  .btn-primary {
    background-color: #5564ae;
    border-color: #5564ae;

    &:hover {
      background-color: darken(#5564ae, 10%);
      color: #fff;
    }
  }

  .btn-outline-primary {
    color: #5564ae;
    border-color: #5564ae;

    &:hover {
      background-color: lighten(#0f8835, 0%);
      color: #fff;
    }
  }
}

footer.footer {
  padding: 1em;
}

.fudrr-wel-btn {
  text-transform: lowercase;
  font-size: 42px;
  padding: 0.75rem 1rem 1rem 1rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);

  svg {
    margin-top: 0.75rem;
    background: rgba(255, 255, 255, 0.2);
    padding: 0.75rem;
    border-radius: 100%;
    min-width: 42px;
    min-height: 42px;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &.fudrr-btn-one {
    background-color:#001e4e;
  }

  &.fudrr-btn-two {
    background-color: #002767;
  }

  &.fudrr-btn-three {
    background-color: #003181;
  }

  &.fudrr-btn-four {
    background-color: #003b9a;
  }

  &.fudrr-btn-five {
    background-color: #0045b4;
  }
}

.fudrr-wel-card {
  h4 {
    text-transform: lowercase;
    font-weight: 600;
    color: rgb(99, 29, 146);
  }

  .card-link {
    color: #999;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
  }
}
